.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/* Netequal */
a {
  color: #106eea;
  text-decoration: none;
}

a:hover {
  color: #3b8af2;
  text-decoration: none;
}

.services .icon-box h4 a {
  color: #222222;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box:hover h4 a,
.services .icon-box:hover .icon i {
  color: #106eea;
}

/*
body {
  font-size: larger;
  line-height: normal;
}
*/

#topbar {
  background: #106eea;
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  color: #fff;
  padding: 0;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
}

.ne-logo {
  max-height: 2.5rem;
}

.ne-navbar {
    background-color: #fff; 
    width: 100%;
}

.navbar-nav a {
  color: #000;
  font-weight: 600;
}



.navbar-nav a,
.navbar-nav a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;

  /*
  font-size: 15px;
  font-weight: 600;
  */

  color: #222222;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}

.navbar-nav a:hover,
.navbar-nav .active,
.navbar-nav .active:focus,
.navbar-nav li:hover>a {
  color: #106eea !important;
}

.navbar-nav>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #106eea;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar-nav a:hover:before,
.navbar-nav .active:before {
  visibility: visible;
  width: 100%;
}


/*
.navbar-nav a:hover,
.navbar-nav .active,
.navbar-nav .active:focus,
.navbar-nav:hover>a {
  color: #106eea;
}
*/


#neHero {
  width: 100%;
  height: 75vh;
  /* background: url(../img/hero-bg.jpg) top left; */
  /* background: url(/public/assets/images/neImage.jpg) top left; */
  background-size: cover;
  position: relative;
}

#neHero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

#neHero h2 {
  color: #ffffff;
  margin: 5px 0 30px 0;
  font-size: 24px;
  font-weight: 700;
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title p {
  margin: 15px auto 0 auto;
  /* font-weight: 600; */
}

.services .icon-box {
  text-align: center;
  border: 1px solid #e2eefd;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f1f6fe;
  border-radius: 4px;
  border: 1px solid #deebfd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
}

.services .icon-box .icon i {
  color: #3b8af2;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
}

.services .icon-box:hover h4 a,
.services .icon-box:hover .icon i {
  color: #106eea;
}

.services .icon-box:hover .icon {
  border-color: #106eea;
}

.ne-fullscreen {
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  -webkit-align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background: url(/public/assets/images/neImage.jpg) top left;
}

.ne-overlay {
  background-color: rgb(0, 0, 0);
  bottom: 0;
  left: 0;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

#netequalMap {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
}

#footer {
  background: #fff;
  padding: 0 0 30px 0;
  color: #444444;
  font-size: 1.2rem;
  background: #f1f6fe;
  box-shadow: 0px 0px 15px 0px;
}

.section-title {
  padding-top: 1.5rem !important;
}



/* Cards settings */
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,900);

html,
body {
  /*
  -moz-box-sizing: border-box;
  box-sizing: border-box; */
  height: 100%;
  width: 100%; 
  background: #FFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
 
.wrapper {
  display: table;
  height: 100%;
  width: 100%;
}

.container-fostrap {
  display: table-cell;
  padding: 1em;
  text-align: center;
  vertical-align: middle;
}
.fostrap-logo {
  width: 100px;
  margin-bottom:15px
}
h1.heading {
  color: #fff;
  font-size: 1.15em;
  font-weight: 900;
  margin: 0 0 0.5em;
  color: #505050;
}


@media screen and (max-width: 767px) {
  #netequalContact, #netequalMap {
    margin-top: 3rem !important;
  }

  .navbar-nav a {
    margin-bottom: 1rem !important;
  }

  .navbar-toggler {
    border-color: gray;
  }

  .navbar-toggler-icon {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  }
}




@media (min-width: 450px) {
  h1.heading {
    font-size: 3.55em;
  }
}
@media (min-width: 760px) {
  h1.heading {
    font-size: 3.05em;
  }
}
@media (min-width: 900px) {
  h1.heading {
    font-size: 3.25em;
    margin: 0 0 0.3em;
  }
} 
.card {
  /* display: block; */
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
  transition: box-shadow .25s;
  border-radius: 20px 20px 0px 0px;
}
.card-img-top {
  border-radius: 20px 20px 0px 0px;
}
.card:hover {
  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}
.img-card {
  width: 100%;
  height:200px;
  border-top-left-radius:2px;
  border-top-right-radius:2px;
  display:block;
    overflow: hidden;
}
.img-card img{
  width: 100%;
  height: 200px;
  object-fit:cover; 
  transition: all .25s ease;
} 
.card-content {
  padding:15px;
  text-align:left;
}
.card-title {
  margin-top:0px;
  font-weight: 700;
  font-size: 1.1em;
}
.card-title a {
  color: #000;
  text-decoration: none !important;
}
.card-read-more {
  border-top: 1px solid #D4D4D4;
}

/*
.card-read-more a {
  text-decoration: none !important;
  padding:10px;
  font-weight:600;
  text-transform: uppercase
}
*/


@media (max-width: 768px) {
  #neHero {
    height: 100vh;
  }

  #neHero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #neHero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #neHero .btn-get-started,
  #neHero .btn-watch-video {
    font-size: 13px;
  }

  .neMainSection div { width: 100% !important; }
}

@media (min-width: 1024px) {
  .section-title p {
    width: 75%;
  }
}

@media screen and (max-width: 1180px) {
	.neMainSection div { width: 100% !important; }
	
}